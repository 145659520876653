import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";

import type { ToastDetails } from "../../providers/ToastProvider";
import AnchorButton from "./AnchorButton";

export function Toast({
  type,
  title,
  message,
  action,
  disappearing,
  onClose,
}: ToastDetails & { onClose: () => void }): JSX.Element {
  return (
    <Transition show={!disappearing}>
      <div
        aria-live="assertive"
        className="transition-max-h pointer-events-auto max-h-40 w-full max-w-sm overflow-hidden rounded-lg bg-white opacity-100 shadow-lg
          ring-1 ring-black ring-opacity-5 transition-all duration-300 data-[closed]:max-h-0 data-[colosed]:opacity-0"
      >
        <div className="p-4">
          <div className="flex items-start">
            <div className="shrink-0">
              {type === "error" && <ExclamationCircleIcon className="size-6 text-red-400" aria-hidden="true" />}
              {type === "success" && <CheckCircleIcon className="size-6 text-green-400" aria-hidden="true" />}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              {title && <p className="mb-0.5 text-sm font-semibold text-gray-900">{title}</p>}
              {message && (
                <p className="text-sm text-gray-500">
                  {message}
                  {action && (
                    <AnchorButton onClick={action.onClick} color="primary">
                      {action.label}
                    </AnchorButton>
                  )}
                </p>
              )}
            </div>
            <div className="ml-4 flex shrink-0">
              <button
                type="button"
                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={onClose}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="size-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}

export function ToastsContainer({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <div className="z-100 pointer-events-none fixed inset-0 flex flex-col items-start space-y-4 px-4 py-6 sm:items-end sm:p-6">
      {children}
    </div>
  );
}
