import { z } from "zod";

export const SuccessResponse = z.object({ status: z.literal("ok"), success: z.literal(true) });
export const ErrorResponse = z.object({ status: z.literal("error"), success: z.literal(false) });
export const StandardResponse = z.union([SuccessResponse, ErrorResponse]);
export const SubscriptionLimitResponse = z.union([
  ErrorResponse.extend({ error: z.literal("subscription_limit:too_many_monthly_agents") }),
  ErrorResponse.extend({ error: z.literal("subscription_limit:too_many_seats_used") }),
  ErrorResponse.extend({ error: z.literal("subscription_limit:team_session_sharing_not_available") }),
  ErrorResponse.extend({ error: z.literal("subscription_limit:no_session_time_credit_left") }),
  ErrorResponse.extend({ error: z.literal("subscription_limit:too_many_sessions") }),
  ErrorResponse.extend({ error: z.literal("team:no_subscription") }),
]);
export const Pagy = z.object({
  page: z.number(),
  items: z.number(),
  count: z.number(),
  pages: z.number(),
});

export type Pagy = z.infer<typeof Pagy>;
