import { createContext, useCallback, useContext, useRef, useState } from "react";

import { Toast, ToastsContainer } from "../components/ui/Toast";
import { uuid } from "../utils";

export type ToastDetails = {
  id: string;
  title?: string;
  message: string;
  disappearing: boolean;
  type?: "error" | "success";
  action?: {
    label: string;
    onClick: () => void;
  };
};

type ProviderProps = {
  children: JSX.Element;
};

const ToastContext = createContext((toast: Omit<ToastDetails, "id" | "disappearing">) => () => {});

export const TOAST_TTL = 8000;

export function useToast() {
  return useContext(ToastContext);
}

export function ToastProvider({ children }: ProviderProps): JSX.Element {
  const [toasts, setToasts] = useState<ToastDetails[]>([]);
  const toastRef = useRef<ToastDetails[]>([]);
  toastRef.current = toasts;

  const removeToast = useCallback((id: string): void => {
    setToasts(toastRef.current.map((n) => (n.id === id ? { ...n, disappearing: true } : n)));
    setTimeout(() => {
      setToasts(toastRef.current.filter((n) => n.id !== id));
    }, 1000);
  }, []);

  const addToast = useCallback(
    (toast: Omit<ToastDetails, "id" | "disappearing">): (() => void) => {
      const id = uuid();
      setToasts((toasts) => [...toasts, { ...toast, id, disappearing: false }]);
      if (toastRef.current.length > 1) removeToast(toastRef.current[1]!.id);

      setTimeout(() => {
        removeToast(id);
      }, TOAST_TTL);

      return () => {
        removeToast(id);
      };
    },
    [removeToast],
  );

  return (
    <ToastContext.Provider value={addToast}>
      <ToastsContainer>
        {toasts.map((n) => (
          <Toast key={n.id} {...n} onClose={() => removeToast(n.id)} />
        ))}
      </ToastsContainer>
      {children}
    </ToastContext.Provider>
  );
}
