import z from "zod";

export const ZapierIntegration = z.object({
  id: z.custom<`zapier_${string}`>(),
  createdAt: z.coerce.date(),
  integrationType: z.literal("zapier"),
  isUsed: z.boolean(),
  lastUsedAt: z.coerce.date(),
  name: z.string(),
  teamId: z.custom<`t_${string}`>(),
  type: z.literal("zapier_integration"),
});

export type ZapierIntegration = z.infer<typeof ZapierIntegration>;
