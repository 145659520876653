import { useMemo } from "react";

export default function useTextMeasure(className: string, text?: string) {
  const measure = useMemo(() => (text ? textToPx(className, text) : 0), [text, className]);

  return measure;
}

function textToPx(className: string, text: string) {
  const span = document.createElement("span");
  span.style.visibility = "hidden";
  if (!className.includes("whitespace-")) {
    span.style.whiteSpace = "nowrap";
  }
  span.className = className;
  span.textContent = text;
  document.body.appendChild(span);
  const width = span.getBoundingClientRect().width;
  document.body.removeChild(span);
  let textWidth = Math.ceil(width);
  if (text.startsWith(" ")) {
    textWidth += 5;
  }
  if (text.endsWith(" ")) {
    textWidth += 5;
  }

  return textWidth;
}
