import z from "zod";

export const ZendeskIntegration = z.object({
  id: z.custom<`zendesk_${string}`>(),
  createdAt: z.coerce.date(),
  integrationType: z.literal("zendesk"),
  isUsed: z.boolean(),
  lastUsedAt: z.coerce.date(),
  name: z.string(),
  teamId: z.custom<`t_${string}`>(),
  type: z.literal("zendesk_integration"),
});

export type ZendeskIntegration = z.infer<typeof ZendeskIntegration>;
