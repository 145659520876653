import { z } from "zod";

export const UsageTrendData = z.object({
  currentUsageSeconds: z.number(),
  previousUsageSeconds: z.number(),
  currentAgentRatingAverage: z.number().nullable(),
  previousAgentRatingAverage: z.number().nullable(),
  currentAgentRatingCount: z.number(),
  previousAgentRatingCount: z.number(),
  usageWeeks: z.number().array(),
  totalLicenses: z.number(),
  licensesNeeded: z.number(),
});
export type UsageTrendData = z.infer<typeof UsageTrendData>;

export const UsageTrend = z.enum(["decrease", "moderateDecrease", "unchanged", "moderateIncrease", "increase"]);
export type UsageTrend = z.infer<typeof UsageTrend>;
