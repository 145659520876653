import { z } from "zod";

export const InstallationStatus = z.enum([
  "on_premise",
  "installed_live",
  "installing",
  "tried_installing",
  "never_installed",
]);

export type InstallationStatus = z.infer<typeof InstallationStatus>;
