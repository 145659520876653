import { z } from "zod";

export const RestKey = z.object({
  id: z.string(),
  name: z.string(),
  secretApiKey: z.string(),
  lastIp: z.string().nullable(),
  lastUsedAt: z.coerce.date().nullable(),
  type: z.literal("rest_key"),
});

export type RestKey = z.infer<typeof RestKey>;
