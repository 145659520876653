import { z } from "zod";

export const ApiLog = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  ip: z.string(),
  statusCode: z.number(),
  requestMethod: z.enum(["GET", "POST", "PUT", "DELETE"]),
  url: z.string(),
  request: z.string(),
  response: z.string(),
  type: z.literal("api_log"),
});

export type ApiLog = z.infer<typeof ApiLog>;
