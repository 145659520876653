import { forwardRef } from "react";

import useCombinedRefs from "../../use/combined-refs";
import { classNames } from "../../utils";

export const Code = forwardRef<HTMLPreElement, { children?: React.ReactNode; selectAll?: boolean; className?: string }>(
  ({ children, selectAll = true, className }, ref): JSX.Element => {
    const innerRef = useCombinedRefs(ref);

    function onClick() {
      if (innerRef.current) {
        const selection = window.getSelection();
        if (selection) {
          const range = document.createRange();
          range.selectNodeContents(innerRef.current);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }

    return (
      <code
        ref={innerRef}
        onClick={onClick}
        className={classNames(
          "bg-gray-200 rounded-sm outline outline-1 outline-gray-200 dark:bg-gray-700 dark:outline-gray-700 px-1",
          className,
        )}
      >
        {children}
      </code>
    );
  },
);
