import { z } from "zod";
import { CustomerSuccessRating } from "./partials/CustomerSuccessRating";
import { CustomerSuccessStage } from "./partials/CustomerSuccessStage";
import { TeamBaseData } from "./Team";
import { UserBaseData } from "./User";

export const CustomerSuccessCheckup = z.object({
  id: z.string(),
  checkupType: z.enum(["rating", "stage_move", "snooze", "note"]),
  createdAt: z.coerce.date(),
  rating: CustomerSuccessRating.nullable(),
  stage: CustomerSuccessStage,
  comment: z.string().nullable(),
  team: TeamBaseData,
  user: UserBaseData,
  type: z.literal("customer_success_checkup"),
});

export type CustomerSuccessCheckup = z.infer<typeof CustomerSuccessCheckup>;

export function checkupTypeLabel(checkupType: CustomerSuccessCheckup["checkupType"]): string {
  switch (checkupType) {
    case "rating":
      return "Rating";
    case "stage_move":
      return "Stage Move";
    case "snooze":
      return "Snooze";
    case "note":
      return "Note";
  }
}
