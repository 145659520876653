import { forwardRef } from "react";

import { classNames } from "../../utils";

type AnchorButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  color?: "red" | "primary";
};

const AnchorButton = forwardRef<HTMLAnchorElement, AnchorButtonProps>(
  ({ children, onClick, disabled, className, color, ...props }, ref) => {
    return (
      <>
        {" "}
        <a
          ref={ref}
          onClick={disabled ? undefined : onClick}
          className={classNames(
            "underline",
            color === "red" && "text-red-800 dark:text-red-200",
            color === "primary" && "text-primary-500",
            disabled ? "cursor-default opacity-50" : "cursor-pointer",
            className,
          )}
          {...props}
        >
          {children}
        </a>{" "}
      </>
    );
  },
);

export default AnchorButton;
