import { z } from "zod";
import { CountryCode } from "./CountryCode";

export const PaymentSourceData = z.object({
  ready: z.boolean(),
  type: z.literal("card"),
  ending: z.string(),
  brand: z.string(),
  country: CountryCode,
  expiresAt: z.coerce.date(),
  active: z.boolean(),
  expired: z.boolean(),
  expiring: z.boolean(),
});

export type PaymentSourceData = z.infer<typeof PaymentSourceData>;
