import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { LinkToParam } from "../components/ui/Link";
import { path } from "../paths";

export default function useRedirect() {
  const navigate = useNavigate();

  const redirect = useCallback(
    (to: LinkToParam) => {
      const target = typeof to === "string" ? to : path(...to);

      // Target will be a path if it is on the same domain, otherwise it will be a URL
      if (target.startsWith("/")) navigate(target);
      else location.href = target;
    },
    [navigate],
  );

  return redirect;
}

type Props = {
  to: LinkToParam;
};

export function Redirect({ to }: Props): React.ReactElement {
  const redirect = useRedirect();

  useEffect(() => {
    redirect(to);
  }, [to, redirect]);

  return <></>;
}
