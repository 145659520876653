import { useCallback, useEffect, useRef, useState } from "react";

import api, { EndpointsWithoutData, EndpointsWithRequiredData } from "../api-client";
import { apiEndpoints, ApiRequests, ApiResponses, Successful, Unsuccessful } from "../data/api-endpoints";
import { useApiCache } from "../providers/ApiCacheProvider";
import useCachedObject, { useCachedState } from "./cached-object";

type UseApiDataReturnType<T extends keyof ApiResponses> = (
  | (Successful<ApiResponses[T]> & { isLoading: false; isRefreshing: boolean })
  | ({ isLoading: true; isRefreshing: true } & Partial<Successful<ApiResponses[T]>>)
) & {
  refresh: (clear?: boolean) => void;
  makeSetter: <K extends keyof Successful<ApiResponses[T]>>(
    key: K,
    clear?: Array<keyof ApiRequests>,
  ) => SetterFor<T, K>;
};

type UseApiDataOptions<T extends keyof ApiResponses> = {
  onError?: (response: Unsuccessful<ApiResponses[T]>) => void;
  enable?: boolean;
};

const promises = new Map<string, Promise<any>>();

export type SetterFor<T extends keyof ApiResponses, K extends keyof Successful<ApiResponses[T]>> = (
  setter: (prev: Successful<ApiResponses[T]>[K]) => Successful<ApiResponses[T]>[K],
) => void;

export function useApiData<T extends EndpointsWithoutData>(
  endpoint: T,
  data?: undefined,
  options?: UseApiDataOptions<T>,
): UseApiDataReturnType<T>;
export function useApiData<T extends EndpointsWithRequiredData>(
  endpoint: T,
  data: ApiRequests[T],
  options?: UseApiDataOptions<T>,
): UseApiDataReturnType<T>;
export function useApiData<T extends keyof typeof apiEndpoints>(
  endpoint: T,
  data?: ApiRequests[T],
  options?: UseApiDataOptions<T>,
): UseApiDataReturnType<T> {
  // Ensure the apiEndpoint doesn't change
  const apiEndpoint = useRef(endpoint);
  if (apiEndpoint.current !== endpoint)
    throw new Error("apiEndpoint cannot change (changed from " + apiEndpoint.current + " to " + endpoint + ")");

  const { getCache, setCache, forgetCache } = useApiCache();
  const [response, setResponse] = useCachedState<Successful<ApiResponses[T]> | null>(null);
  const [everLoaded, setEverLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const settersCache = useRef<Map<string, Function>>(new Map());
  const cachedData = useCachedObject(data);
  const isLoadingRef = useRef(isLoading);
  isLoadingRef.current = isLoading;
  const responseRef = useRef(response);
  responseRef.current = response;
  const optionsRef = useRef(options);
  optionsRef.current = options;

  const enabled = options?.enable ?? true;

  const storeCache = useCallback(
    (endpoint: T, data: ApiRequests[T] | undefined, response: ApiResponses[T]) => {
      if (response.success) setCache(endpoint, data, response);
    },
    [setCache],
  );

  const refresh = useCallback(
    (clear?: boolean) => {
      if (clear) {
        setResponse(null);
        setEverLoaded(false);
      } else if (isLoadingRef.current) {
        return; // Ignore refresh if a request is already happening
      }
      setIsLoading(true);
      const promiseKey = JSON.stringify({ endpoint, data: cachedData });
      const promise = promises.get(promiseKey) ?? api(endpoint as any, cachedData);
      const requestedData = cachedData;
      promises.set(promiseKey, promise);
      promise.then((response: ApiResponses[T]) => {
        promises.delete(promiseKey);
        storeCache(endpoint, cachedData, response);
        if (cachedData !== requestedData) return; // This response is no longer relevant

        if (response.success) {
          setResponse(response as any); // as Successful<ApiResponses[T]>;
          setIsLoading(false);
          setEverLoaded(true);
        } else {
          optionsRef.current?.onError?.(response as any); // as Unsuccessful<ApiResponses[T]>;
        }
      });
    },
    [cachedData, endpoint, storeCache, setResponse],
  );

  useEffect(() => {
    if (!enabled) return;

    const [isFresh, response] = getCache(endpoint, cachedData);
    if (response) {
      if (!isFresh) refresh();

      setResponse(response);
      setIsLoading(false);
      setEverLoaded(true);
    } else {
      refresh(true);
    }
  }, [endpoint, cachedData, getCache, refresh, enabled, setResponse]);

  useEffect(() => {
    if (enabled && everLoaded && !getCache(endpoint, data)[0]) refresh();
  }, [everLoaded, endpoint, refresh, getCache, data, enabled]);

  const makeSetter = useCallback(
    <K extends keyof Successful<ApiResponses[T]>>(key: K, forget?: Array<keyof ApiRequests>) => {
      // Generate a unique identifier for the cache key
      const forgetKey = forget ? forget.join(",") : "";
      const cacheKey = `${String(key)}-${forgetKey}`;

      // Check if the setter function for this cacheKey is already cached
      if (settersCache.current.has(cacheKey)) return settersCache.current.get(cacheKey);

      // Create a new setter function
      const newSetter = (func: (prevValue: Successful<ApiResponses[T]>[K]) => Successful<ApiResponses[T]>[K]) => {
        const response = responseRef.current;
        if (response?.success) {
          response[key] = func(response[key]);
          setResponse(response);
          storeCache(endpoint, cachedData, response as any);
        }
        if (forget) for (const key of forget) forgetCache(key);
      };

      // Cache and return the new setter function
      settersCache.current.set(cacheKey, newSetter);
      return newSetter;
    },
    [endpoint, forgetCache, storeCache, cachedData, setResponse],
  );

  return {
    ...response,
    isLoading: !everLoaded,
    isRefreshing: isLoading,
    refresh,
    makeSetter,
  } as UseApiDataReturnType<T>;
}

type UseApiReturnType<T extends keyof ApiRequests, D extends Partial<ApiRequests[T]> | undefined> = {
  isLoading: boolean;
  call: (data: D extends undefined ? ApiRequests[T] : Omit<ApiRequests[T], keyof D>) => Promise<ApiResponses[T]>;
};

export function useApi<T extends keyof ApiRequests>(endpoint: T): UseApiReturnType<T, undefined>;
export function useApi<T extends keyof ApiRequests>(endpoint: T): UseApiReturnType<T, undefined>;
export function useApi<T extends keyof ApiRequests, D extends Partial<ApiRequests[T]>>(
  endpoint: T,
  defaultData?: D,
  invalidates?: Array<keyof ApiRequests>,
): UseApiReturnType<T, D>;
export function useApi<T extends keyof ApiRequests, D extends Partial<ApiRequests[T]>>(
  endpoint: T,
  defaultData?: D,
  invalidates?: Array<keyof ApiRequests>,
): UseApiReturnType<T, D> {
  const [isLoading, setIsLoading] = useState(false);
  const { invalidateCache, forgetCache } = useApiCache();
  // Ensure the apiEndpoint doesn't change
  const apiEndpoint = useRef(endpoint);
  if (apiEndpoint.current !== endpoint) throw new Error("apiEndpoint cannot change");

  const cachedDefaultData = useCachedObject(defaultData);
  const cachedInvalidates = useCachedObject(invalidates);

  const call = useCallback(
    async (data?: Omit<ApiRequests[T], keyof D>): Promise<ApiResponses[T]> => {
      setIsLoading(true);
      try {
        return await api(endpoint as any, { ...cachedDefaultData, ...data });
      } finally {
        let responseAt = new Date();
        setTimeout(() => setIsLoading(false), 0); // In case we update state in the same tick
        setTimeout(() => {
          invalidateCache(
            responseAt,
            Object.entries({ ...cachedDefaultData, ...data })
              .filter(([key, value]: [string, any]) => key.endsWith("Id"))
              .map(([key, value]) => value),
          );
          cachedInvalidates?.forEach((key) => forgetCache(key));
        }, 100);
      }
    },
    [cachedDefaultData, endpoint, invalidateCache, forgetCache, cachedInvalidates],
  );

  return { isLoading, call } as UseApiReturnType<T, D>;
}
