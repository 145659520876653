import { z } from "zod";

import { ApiLog } from "../models/ApiLog";
import { CobrowsingSessionWebhookLog } from "../models/CobrowsingSessionWebhookLog";
import { RestKey } from "../models/RestKey";

import { Pagy, SuccessResponse } from "./standard-responses";

export const cobrowsingApiEndpoints = {
  "co.keys.index": (p: { teamDomain: string }) => ["co", "get", `/dashboard/${p.teamDomain}/rest_keys/index`],
  "co.keys.create": (p: { teamDomain: string }) => ["co", "post", `/dashboard/${p.teamDomain}/rest_keys`],
  "co.keys.delete": (p: { teamDomain: string; keyId: string }) => [
    "co",
    "delete",
    `/dashboard/${p.teamDomain}/rest_keys/${p.keyId}`,
  ],
  "co.history.index": (p: { teamDomain: string }) => ["co", "get", `/dashboard/${p.teamDomain}/history/index`],
  "co.webhooks.index": (p: { teamDomain: string }) => ["co", "get", `/dashboard/${p.teamDomain}/webhooks/index`],
  "co.cobrowse.notes.show": () => ["co", "get", `/session/notes`],
  "co.cobrowse.notes.update": () => ["co", "put", `/session/notes`],
} as const satisfies Record<string, (p: any) => ["co", "get" | "delete" | "post" | "put", `/${string}`]>;

export const cobrowsingApiRequests = {
  "co.keys.create": z.object({
    teamDomain: z.string(),
    restKey: z.object({
      name: z.string(),
    }),
  }),
  "co.keys.delete": z.object({
    teamDomain: z.string(),
    keyId: z.string(),
  }),
  "co.keys.index": z.object({
    teamDomain: z.string(),
  }),
  "co.history.index": z.object({
    teamDomain: z.string(),
    page: z.number().optional().nullable(),
    maxId: z.string().optional().nullable(),
  }),
  "co.webhooks.index": z.object({
    teamDomain: z.string(),
    page: z.number().optional().nullable(),
    maxId: z.string().optional().nullable(),
  }),
  "co.cobrowse.notes.show": z.object({
    sessionId: z.string(),
  }),
  "co.cobrowse.notes.update": z.object({
    sessionId: z.string(),
    notes: z.string().nullable(),
  }),
} as const;

export const cobrowsingApiResponses = {
  "co.keys.create": SuccessResponse.extend({
    restKey: RestKey,
  }),
  "co.keys.delete": SuccessResponse,
  "co.keys.index": SuccessResponse.extend({
    restKeys: RestKey.array(),
  }),
  "co.history.index": SuccessResponse.extend({
    apiLogs: ApiLog.array(),
    pagy: Pagy,
  }),
  "co.webhooks.index": SuccessResponse.extend({
    sessionWebhookLogs: CobrowsingSessionWebhookLog.array(),
    pagy: Pagy,
  }),
  "co.cobrowse.notes.show": SuccessResponse.extend({
    notes: z.string().nullable(),
  }),
  "co.cobrowse.notes.update": SuccessResponse.extend({
    notes: z.string().nullable(),
  }),
} as const;
