import { useMemo, useRef, useState } from "react";
import { isEqual } from "lodash";

export default function useCachedObject<T>(object: T): T {
  const cacheRef = useRef<T>(object);
  const cached = useMemo(() => {
    if (!isEqual(cacheRef.current, object)) {
      cacheRef.current = object;
    }
    return cacheRef.current;
  }, [object]);
  return cached;
}

export function useCachedState<T>(object: T): [T, (newState: T) => void] {
  const [state, setState] = useState(object);
  const cached = useCachedObject(state);
  return [cached, setState];
}
