import { z } from "zod";

export const CustomerSuccessStage = z.enum([
  "onboarding",
  "adoption",
  "growth",
  "retention",
  "renewal",
  "losing",
  "lost",
  "ignored",
]);
export type CustomerSuccessStage = z.infer<typeof CustomerSuccessStage>;
