import { use, t as i18t, exists } from "i18next";
import { initReactI18next } from "react-i18next";

export function initI18n(language = "en"): void {
  use(initReactI18next).init({
    fallbackLng: "en",
    lng: language,
    resources: {
      "en": {
        translation: require(process.env.STORYBOOK ? "./locales/mock-locale.json" : "./locales/en.yml").en,
      },
      "pt-BR": {
        translation: require(process.env.STORYBOOK ? "./locales/mock-locale.json" : "./locales/pt-BR.yml")["pt-BR"],
      },
      "it": {
        translation: require(process.env.STORYBOOK ? "./locales/mock-locale.json" : "./locales/it.yml").it,
      },
      "ja": {
        translation: require(process.env.STORYBOOK ? "./locales/mock-locale.json" : "./locales/ja.yml").ja,
      },
    },
    interpolation: {
      prefix: "%{",
      suffix: "}",
      escapeValue: false,
    },
  });
}

export default function t(value: string, options: Record<string, any> = {}): string {
  const key =
    "t_" +
    value
      .replace(/\?/g, "_q")
      .replace(/!/g, "_e")
      .replace(/\./g, "_d")
      .replace(/[^A-z0-9]+/g, "_")
      .replace(/(^_|_$)/g, "");

  if (!exists(key)) {
    return value.replace(/%{(.+?)}/g, (_, interpolationKey) => options[interpolationKey]);
  }

  return i18t(key, options);
}
