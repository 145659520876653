import { z } from "zod";

import { User } from "./User";

export const TrackedEmail = z.object({
  id: z.string(),
  senderName: z.string(),
  senderEmail: z.string(),
  recipients: z
    .object({
      name: z.string().nullable(),
      email: z.string(),
    })
    .array(),
  ccRecipients: z
    .object({
      name: z.string().nullable(),
      email: z.string(),
    })
    .array(),
  subject: z.string(),
  body: z.string(),
  sentAt: z.coerce.date(),
  hasAttachments: z.boolean(),
  userFrom: User.pick({
    id: true,
    isAdmin: true,
    type: true,
  }).nullable(),
  userTo: User.pick({
    id: true,
    isAdmin: true,
    type: true,
  }).nullable(),
});

export type TrackedEmail = z.infer<typeof TrackedEmail>;
