import z from "zod";

export const FreshchatIntegration = z.object({
  id: z.custom<`freshchat_${string}`>(),
  createdAt: z.coerce.date(),
  integrationType: z.literal("freshchat"),
  isUsed: z.boolean(),
  lastUsedAt: z.coerce.date(),
  name: z.string(),
  teamId: z.custom<`t_${string}`>(),
  type: z.literal("freshchat_integration"),
});

export type FreshchatIntegration = z.infer<typeof FreshchatIntegration>;
