import { z } from "zod";

import { authEndpoints, authRequests, authResponses } from "./auth";
import { cobrowsingApiEndpoints, cobrowsingApiRequests, cobrowsingApiResponses } from "./cobrowsingapi";
import { helloScreenEndpoints, helloScreenRequests, helloScreenResponses } from "./helloscreen";
import { hqEndpoints, hqRequests, hqResponses } from "./hq";
import { onboardingEndpoints, onboardingRequests, onboardingResponses } from "./onboarding";
import { settingsEndpoints, settingsRequests, settingsResponses } from "./settings";

import type { AppApiRequests, AppApiResponses } from "~services/app/frontendApi";

export const apiEndpoints = {
  ...authEndpoints,
  ...cobrowsingApiEndpoints,
  ...helloScreenEndpoints,
  ...hqEndpoints,
  ...onboardingEndpoints,
  ...settingsEndpoints,
} as const satisfies Record<
  string,
  (p: any) => ["*" | "up" | "hs" | "uv" | "hq" | "co" | "ld", "get" | "delete" | "post" | "put", `/${string}`]
>;

export const apiRequests = {
  ...authRequests,
  ...cobrowsingApiRequests,
  ...helloScreenRequests,
  ...hqRequests,
  ...onboardingRequests,
  ...settingsRequests,
} as const satisfies { [K in keyof typeof apiEndpoints]: any };

export const apiResponses = {
  ...authResponses,
  ...cobrowsingApiResponses,
  ...helloScreenResponses,
  ...hqResponses,
  ...onboardingResponses,
  ...settingsResponses,
} as const satisfies { [K in keyof typeof apiEndpoints]: any };

export type ApiRequests = {
  [key in keyof typeof apiRequests]: z.infer<(typeof apiRequests)[key]>;
} & AppApiRequests;

export type ApiResponses = {
  [key in keyof typeof apiResponses]: z.infer<(typeof apiResponses)[key]>;
} & AppApiResponses;

export type Successful<T> = Extract<T, { status: "ok" }>;
export type Unsuccessful<T> = Extract<T, { status: "error" }>;
