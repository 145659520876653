import { z } from "zod";

import { ContainsAllCheck } from "../../utils";
import { Camelize } from "../../utils/types";

import { SalesRating } from "./partials/SalesRating";
import { CompanyBaseData } from "./Company";
import { SalesOpportunity } from "./SalesOpportunity";
import { UserBaseData } from "./User";

export const SalesActivity = z.object({
  id: z.string(),
  company: CompanyBaseData,
  user: UserBaseData,
  automated: z.boolean(),
  activityType: z.enum([
    "call",
    "email",
    "meeting",
    "stage_change",
    "note",
    "social_message_sent",
    "social_message_received",
    "attempted_call",
  ]),
  description: z.string().nullable(),
  salesOpportunity: SalesOpportunity.pick({ id: true, type: true }).nullable(),
  opportunityValue: z.number().nullable(),
  opportunityHealth: SalesRating.nullable(),
  createdAt: z.coerce.date(),
  editableUntil: z.coerce.date(),
  type: z.literal("sales_activity"),
});

export type SalesActivity = z.infer<typeof SalesActivity>;

export function salesActivityTypeLabel(type: SalesActivity["activityType"]) {
  switch (type) {
    case "call":
      return "Call";
    case "email":
      return "Email";
    case "meeting":
      return "Meeting";
    case "stage_change":
      return "Stage Change";
    case "social_message_sent":
      return "Social media message sent";
    case "social_message_received":
      return "Social media message received";
    case "note":
      return "Note";
    case "attempted_call":
      return "Attempted call";
  }
}

export function salesActivityTypeVerb(type: SalesActivity["activityType"]): string {
  switch (type) {
    case "stage_change":
    case "note":
    case "attempted_call":
    case "social_message_sent":
    case "social_message_received":
      return "by";
    case "call":
    case "email":
      return "from";
    case "meeting":
      return "with";
  }
}

export function salesActivityCreationAllowed(type: SalesActivity["activityType"]): boolean {
  switch (type) {
    case "stage_change":
      return false;
    case "note":
    case "call":
    case "email":
    case "meeting":
    case "social_message_sent":
    case "social_message_received":
    case "attempted_call":
      return true;
  }
}

export const activityTypeKeys = [
  "call",
  "email",
  "meeting",
  "stageChange",
  "note",
  "socialMessageSent",
  "socialMessageReceived",
  "attemptedCall",
] as const;

const check: ContainsAllCheck<Camelize<SalesActivity["activityType"]>, (typeof activityTypeKeys)[number]> = true;
