import { z } from "zod";

import { zNoEmptyObject } from "../../utils";
import { Url } from "../models/partials/Url";
import { UtmParams } from "../models/partials/UtmParams";
import { Team, TeamBaseData } from "../models/Team";
import { User } from "../models/User";

import { ErrorResponse, SuccessResponse } from "./standard-responses";

export const authEndpoints = {
  "auth.logout": () => ["*", "delete", "/auth/logout"],
  "auth.password.set": () => ["*", "post", "/auth/password"],
  "auth.signUp.setup": () => ["up", "get", "/auth/sign_up/status"],
  "auth.signUp.create": () => ["up", "post", "/auth/sign_up"],
  "auth.join.setup": (p: { teamDomain: string; teamUniqueIdentifier: string }) => [
    "up",
    "get",
    `/join/${p.teamUniqueIdentifier}-${p.teamDomain}/status`,
  ],
  "auth.join.askInvite": (p: { teamDomain: string; teamUniqueIdentifier: string }) => [
    "up",
    "post",
    `/ask_to_join/${p.teamUniqueIdentifier}-${p.teamDomain}`,
  ],
  "auth.join.byEmail": (p: { teamDomain: string; teamUniqueIdentifier: string }) => [
    "up",
    "post",
    `/join/${p.teamUniqueIdentifier}-${p.teamDomain}/by_email`,
  ],
  "auth.logIn.create": () => ["up", "post", "/auth/login"],
  "auth.mfa.setup": () => ["up", "get", "/auth/mfa/setup/qr"],
  "auth.mfa.enable": () => ["up", "post", "/auth/mfa/setup"],
  "auth.accessTokens.create": () => ["up", "post", "/auth/tokens"],
  "up.teams.create": () => ["*", "post", "/settings/teams"],
} as const satisfies Record<string, (p: any) => ["up" | "*", "get" | "delete" | "post" | "put", `/${string}`]>;

export const authRequests = {
  "auth.logout": z.undefined(),
  "auth.password.set": z.object({
    user: z.object({ password: z.string() }),
  }),
  "auth.signUp.setup": z.undefined(),
  "auth.signUp.create": z.object({
    user: User.pick({ name: true, phoneNumber: true, email: true }),
    product: z.string().optional().nullable(),
    integration: z.string().optional().nullable(),
    ignoreTakenDomain: z.boolean().optional(),
    utmParams: UtmParams.optional(),
  }),
  "auth.join.setup": z.object({
    teamDomain: z.string(),
    teamUniqueIdentifier: z.string(),
  }),
  "auth.join.byEmail": z.object({
    emailUsername: z.string(),
    teamDomain: z.string(),
    teamUniqueIdentifier: z.string(),
  }),
  "auth.join.askInvite": z.object({
    user: User.pick({ name: true, email: true }),
    teamDomain: z.string(),
    teamUniqueIdentifier: z.string(),
  }),
  "auth.logIn.create": z.union([
    z.object({
      email: User.shape.email,
      redirectUrl: z.string().optional(),
      noPassword: z.literal(true).optional(),
      password: z.string().optional(),
      mfaCode: z.string().optional(),
    }),
    z.object({
      loginToken: z.string(),
      redirectUrl: z.string().optional(),
      mfaCode: z.string().optional(),
    }),
  ]),
  "auth.mfa.setup": z.undefined(),
  "auth.mfa.enable": z.object({
    code: z.string(),
  }),
  "auth.accessTokens.create": z.undefined(),
  "up.teams.create": z.object({
    team: z.object({
      domain: z.string(),
    }),
  }),
} as const;

export const authResponses = {
  "auth.logout": SuccessResponse,
  "auth.password.set": SuccessResponse.or(
    ErrorResponse.extend({ error: z.literal("validation_error"), userErrors: z.object({ password: z.string() }) }),
  )
    .or(ErrorResponse.extend({ error: z.literal("password_missing") }))
    .or(ErrorResponse.extend({ error: z.literal("password_not_needed") })),
  "auth.signUp.setup": SuccessResponse.extend({ inviteEmail: User.shape.email.nullable() }),
  "auth.signUp.create": z.union([
    SuccessResponse.extend({ authenticated: z.boolean(), redirectUrl: z.string().optional() }),
    ErrorResponse.extend({
      success: z.literal(false),
      error: z.literal("invalid_user"),
      userErrors: zNoEmptyObject(z.object({ name: z.string(), email: z.string(), phoneNumber: z.string() })),
    }).strict(),
    ErrorResponse.extend({
      teamExists: z.literal(true),
      teamAllowsSelfJoin: z.boolean(),
      team: Team.pick({ domain: true, uniqueIdentifier: true }),
    }),
  ]),
  "auth.join.setup": z.union([
    ErrorResponse.extend({ error: z.literal("already_a_member") }),
    SuccessResponse.extend({
      inviteRequired: z.boolean(),
      samlLink: Url.nullable(),
      joinByEmailDomain: z.string().nullable(),
      joinBySso: z
        .object({
          name: z.string(),
          link: Url,
        })
        .array(),
    }).strict(),
  ]),
  "auth.join.byEmail": z.union([
    ErrorResponse.extend({
      error: z.enum(["missing_email", "existing_user", "invite_required", "invalid_email"]),
    }),
    SuccessResponse,
  ]),
  "auth.join.askInvite": SuccessResponse.or(ErrorResponse.extend({ error: z.literal("record_not_found") })),
  "auth.logIn.create": z.union([
    SuccessResponse.extend({ authStatus: z.enum(["authenticated", "check_email"]) }),
    SuccessResponse.extend({ authStatus: z.literal("login_link"), loginLink: z.string() }),
    ErrorResponse.extend({
      authStatus: z.enum([
        "invalid_email",
        "invalid_token",
        "invalid_password",
        "password_required",
        "invalid_mfa_code",
        "mfa_code_required",
      ]),
    }),
  ]),
  "auth.mfa.setup": SuccessResponse.extend({
    qrUrl: z.string(),
    force: z.boolean(),
  })
    .or(ErrorResponse.extend({ error: z.literal("already_enabled") }))
    .or(ErrorResponse.extend({ error: z.literal("user_is_oauth") })),
  "auth.mfa.enable": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("invalid_code"),
    }),
  ),
  "auth.accessTokens.create": SuccessResponse.extend({
    code: z.string(),
  }),
  "up.teams.create": SuccessResponse.extend({
    team: TeamBaseData,
  })
    .or(
      ErrorResponse.extend({
        error: z.literal("validation_error"),
        teamErrors: z.object({
          domain: z.string(),
        }),
      }),
    )
    .or(
      ErrorResponse.extend({
        error: z.literal("not_allowed"),
      }),
    ),
} as const;
