import { z } from "zod";
import { UserBaseData } from "../User";

export const ExternalAgent = z.object({
  id: z.string(),
  name: z.string().nullable(),
  externalId: z.string(),
  agentNickname: z.string().nullable(),
  onPremise: z.boolean(),
  firstSeenAt: z.coerce.date(),
  lastSeenAt: z.coerce.date(),
  type: z.literal("external_agent"),
});

export type ExternalAgent = z.infer<typeof ExternalAgent>;

export const CobrowsingAgentUsage = z.object({
  agent: z.union([
    UserBaseData,
    ExternalAgent.pick({ externalId: true, name: true, onPremise: true, id: true, type: true }),
  ]),
  totalSeconds: z.number(),
  totalMinutes: z.number(),
  totalAudioMinutes: z.number(),
  formattedTime: z.string(),
  formattedAudioTime: z.string(),
  screensharesCount: z.number(),
  usersCount: z.number(),
  historyViewsCount: z.number(),
});

export type CobrowsingAgentUsage = z.infer<typeof CobrowsingAgentUsage>;

export const CobrowsingTeamUsage = z.object({
  agentsCount: z.number(),
  agentsUsages: z.array(CobrowsingAgentUsage).nullable(),
  formattedAudioTime: z.string(),
  formattedTime: z.string(),
  formattedTimePerSession: z.string(),
  from: z.coerce.date(),
  screensharesCount: z.number(),
  to: z.coerce.date(),
  totalAudioMinutes: z.number(),
  totalAudioSeconds: z.number(),
  totalMinutes: z.number(),
  totalSeconds: z.number(),
  usersCount: z.number(),
});

export type CobrowsingTeamUsage = z.infer<typeof CobrowsingTeamUsage>;
