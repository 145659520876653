import z from "zod";

export const TwilioFlexIntegration = z.object({
  id: z.custom<`twilio_flex_${string}`>(),
  createdAt: z.coerce.date(),
  integrationType: z.literal("twilio_flex"),
  isUsed: z.boolean(),
  lastUsedAt: z.coerce.date(),
  name: z.string(),
  teamId: z.custom<`t_${string}`>(),
  type: z.literal("twilio_flex_integration"),
});

export type TwilioFlexIntegration = z.infer<typeof TwilioFlexIntegration>;
