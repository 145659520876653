---
"it":
  "t_0_mins": >-
    zero minuti
  "t_1_day_ago": >-
    1 giorno fa
  "t_1_hour_ago": >-
    1 ora fa
  "t_1_hr": >-
    1 ora
  "t_1_min": >-
    1 minuto
  "t_1_minute_ago": >-
    1 minuto fa
  "t_1_second": >-
    1 secondo
  "t_cannot_be_set_when_there_is_an_existing_subscription": >-
    non può essere modificato quando c'é una iscrizione attiva
  "t_currently_online": >-
    Attualmente online
  "t_days_days_ago": >-
    %{days} giorni fa
  "t_google_authentication_error": >-
    Errore con Google
  "t_hours_hours_ago": >-
    %{hours} ore fa
  "t_hours_hrs": >-
    %{hours} ore
  "t_in_1_day": >-
    fra 1 giorno
  "t_in_1_hour": >-
    fra un ora
  "t_in_1_minute": >-
    fra un minuto
  "t_in_days_days": >-
    fra %{days} giorni
  "t_in_hours_hours": >-
    fra %{hours} ore
  "t_in_minutes_minutes": >-
    fra %{minutes} minuti
  "t_just_now": >-
    qualche secondo fa
  "t_minutes_mins": >-
    %{minutes} minuti
  "t_minutes_minutes_ago": >-
    %{minutes} minuti fa
  "t_please_enter_a_valid_email_address": >-
    Inserisci un indirizzo email valido.
  "t_seconds_second": >-
    %{seconds} secondi
  "t_seconds_seconds": >-
    %{seconds} secondi
  "t_sign_in_error": >-
    Errore di autenticazione
  "t_something_and_somethingelse": >-
    %{something} e %{somethingElse}
  "t_something_went_wrong_please_try_again_later": >-
    Qualcosa non ha funzionato. Riprova più tardi.
  "t_unchanged": >-
    Uguale
  "t_we_have_asked_your_admin": >-
    Abbiamo chiesto al tuo amministratore
  "t_you_are_not_authorized_to_access_this_page": >-
    Non sei autorizzato ad accedere a questa pagina.
  "t_you_ve_authorized_integration_type_to_access_your_account": >-
    Hai autorizzato %{integration_type} ad accedere al tuo account
