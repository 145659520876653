import { z } from "zod";

import { CompanyBaseData } from "./Company";
import { UserBaseData } from "./User";

export const SalesTask = z.object({
  id: z.string(),
  company: CompanyBaseData,
  createdAt: z.coerce.date(),
  createdBy: UserBaseData.nullable(),
  assignedTo: UserBaseData.nullable(),
  name: z.string(),
  description: z.string().nullable(),
  priority: z.enum(["low", "medium", "high"]),
  status: z.enum(["open", "in_progress", "done", "canceled"]),
  dueDate: z.custom<`${number}-${number}-${number}`>().nullable(),
  snoozedUntil: z.custom<`${number}-${number}-${number}`>().nullable(),
  completedAt: z.coerce.date().nullable(),
  canceledAt: z.coerce.date().nullable(),
  type: z.literal("sales_task"),
});

export type SalesTask = z.infer<typeof SalesTask>;

export function taskPriorityLabel(priority: SalesTask["priority"]) {
  switch (priority) {
    case "low":
      return "Low";
    case "medium":
      return "Medium";
    case "high":
      return "High";
  }
}

export function taskStatusLabel(status: SalesTask["status"]) {
  switch (status) {
    case "open":
      return "To do";
    case "in_progress":
      return "In Progress";
    case "done":
      return "Done";
    case "canceled":
      return "Canceled";
  }
}
