import { z } from "zod";
import { User } from "./User";
import { Company } from "./Company";

export const Contact = z.object({
  id: z.string(),
  name: z.string().nullable(),
  email: z.string(),
  phoneNumber: z.string().nullable(),
  role: z.enum(["technical", "manager", "procurement", "security", "consultant"]).nullable(),
  lastContactedAt: z.coerce.date().nullable(),
  lastHeardFromAt: z.coerce.date().nullable(),
  comment: z.string().nullable(),
  user: User.pick({
    id: true,
    type: true,
  }).nullable(),
  company: Company.pick({
    id: true,
    type: true,
  }).nullable(),
});

export type Contact = z.infer<typeof Contact>;
