import { z } from "zod";

import Day from "./Day";

export type Camelize<T extends string> = T extends `${infer A}_${infer B}${infer C}`
  ? `${A}${Capitalize<B>}${Camelize<C>}`
  : T;

export const zDay = z
  .custom<`${number}-${number}-${number}`>((data: any) => typeof data === "string" && /^\d+-\d+-\d+$/.test(data))
  .transform((date) => new Day(date));
