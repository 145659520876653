import { z } from "zod";
import { CountryCode } from "./CountryCode";

export const TaxInfo = z.object({
  vatNumber: z.string().optional().nullable(),
  vatName: z.string().optional().nullable(),
  vatAddress: z.string().optional().nullable(),
  vatCountry: CountryCode.optional().nullable(),
  country: CountryCode.optional().nullable(),
  taxRate: z.number(),
  taxType: z.enum(["vat", "sales"]),
});

export type TaxInfo = z.infer<typeof TaxInfo>;
