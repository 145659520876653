import { z } from "zod";

import { Url } from "./partials/Url";
import { PageView } from "./PageView";

export const VisitorModel = z.object({
  shortId: z.string(),
  watchLink: Url,
  apiKey: z.string(),
  email: z.string().nullable(),
  name: z.string().nullable(),
  nickname: z.string(),
  ipAddress: z.string(),
  jsConfiguration: z.boolean(),
  locationCity: z.string().nullable(),
  locationCountry: z.string().nullable(),
  locationCountryName: z.string().nullable(),
  lastUrl: z.string().nullable(),
  deviceType: z.string().nullable(),
  deviceName: z.string().nullable(),
  browserName: z.string().nullable(),
  integration: z.string().nullable(),
  lastSeenAt: z.coerce.date(),
  isOnline: z.boolean(),
  addedAt: z.coerce.date(),
  isInSession: z.boolean(),
  identities: z.array(z.string()),
  tags: z.array(z.string()),
  uniqueId: z.string().nullable(),
  lookupCode: z.string().nullable(),
  callName: z.string().nullable(),
  isSupported: z.boolean(),
  isWaitingForCall: z.boolean(),
  sessions: z.array(z.record(z.any())).optional(),
  history: z.array(PageView).optional(),
  type: z.literal("visitor"),
});

export type VisitorModel = z.infer<typeof VisitorModel>;
