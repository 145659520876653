import { z } from "zod";

import { Feature as ApiFeature } from "~data/Feature";

import { ContainsAllCheck } from "../../../utils";

export const Feature = z.enum([
  "disable_advert",
  "console_access",
  "sso",
  "reporting",
  "activity_log",
  "team_session_sharing",
  "user_roles",
  "remote_control",
  "audio_calls",
  "agent_request_button",
  "console_control",
  "sessions_list",
  "zapier_integration",
  "salesforce_integration",
  "intercom_session_summary",
  "agent_redirect",
  "agent_notes",
  "universal_proxy",
  "session_recording",
  "automatic_screenshots",
  "user_full_screen",
  "agent_share_screen",
]);

export type Feature = z.infer<typeof Feature>;

const check: ContainsAllCheck<Feature, ApiFeature> = true;
