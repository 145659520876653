import * as Sentry from "@sentry/browser";
import axios from "axios";
import applyCaseMiddleware from "axios-case-converter";

import type { SubscriptionIssues } from "~services/app/endpointGuards";
import { EndpointAuth } from "~services/app/endpointGuards";
import type { ServerPrefix, VerbKeywords } from "~services/app/endpointNames";

import { makeApiErrorEvent, makeRailsErrorEvent } from "./api-errors";
import type { ApiRequests, ApiResponses } from "./data/api-endpoints";
import { apiResponses } from "./data/api-endpoints";
import { apiEndpoints } from "./data/api-endpoints";
import { getRouteOrigin } from "./paths";
import { makeNewVersionAvailableEvent } from "./providers/ApiCacheProvider";
import { ContainsAllCheck, containsFile, isPlainObject, presence, sleep, trimStrings } from "./utils";
import Day from "./utils/Day";

let failingSince: Date | undefined = undefined,
  mfaCode: string | undefined = undefined,
  mfaCodePromise: Promise<void> | undefined = undefined;

const authenticationToken = location.pathname.startsWith("/embed")
  ? localStorage.getItem("upscope:authentication-token")
  : null;

const verbKeywords = {
  get: ["get", "show", "index", "list", "stats", "verify", "search"] as const,
  post: ["create", "perform", "upload", "report", "refetch", "share"] as const,
  put: ["update", "transfer", "publish", "toggle", "add"] as const,
  delete: ["delete", "unlink", "remove", "terminate"] as const,
} as const;

const getVerbKeywordsCheck: ContainsAllCheck<VerbKeywords["get"], (typeof verbKeywords)["get"][number]> = true;
const postVerbKeywordsCheck: ContainsAllCheck<VerbKeywords["post"], (typeof verbKeywords)["post"][number]> = true;
const putVerbKeywordsCheck: ContainsAllCheck<VerbKeywords["put"], (typeof verbKeywords)["put"][number]> = true;
const deleteVerbKeywordsCheck: ContainsAllCheck<VerbKeywords["delete"], (typeof verbKeywords)["delete"][number]> = true;

const serverPrefixes = {
  up: "up",
  hq: "hq",
  ld: "ld",
  uv: "uv",
  hs: "hs",
  co: "co",
  auth: "*",
  global: "*",
} as const satisfies ServerPrefix;

export const standardRailsUnauthorizedResponses = [
  "permission:denied",
  "auth:logged_in",
  "auth:not_logged_in",
  "auth:mfa_setup_required",
  "admin:not_admin",
  "admin:impersonating",
  "admin:wrong_role",
  "billing:subscription_needed",
  "billing:missing_feature",
  "billing:change_not_allowed",
  "team_status:baa",
  "team_status:banned",
  "team_status:on_premise",
  "team_status:not_on_premise",
  "admin:mfa_required",
] as const;

const railsAxiosClient = applyCaseMiddleware(
  axios.create({
    baseURL: "/",
    headers: {
      "Content-Type": "application/json",
      "X-Integration-Authentication-Token": authenticationToken,
      "Accept": "application/json",
      "X-Version": process.env.CURRENT_APP_VERSION,
    },
    validateStatus: (status) => status < 501 || (!failingForTooLong() && [429, 502, 503].includes(status)),
  }),
);

const axiosClient = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
    "X-Integration-Authentication-Token": authenticationToken,
    "Accept": "application/json",
    "X-Version": process.env.CURRENT_APP_VERSION,
    "X-Client-Timezone": timezone(),
  },
  validateStatus: (status) => status < 501 || (!failingForTooLong() && [429, 502, 503].includes(status)),
});

type RequestHeaders = {
  "X-Mfa-Code"?: string;
  "Content-Type"?: string;
};

export type EndpointsWithRequiredData = {
  [key in keyof ApiRequests]: ApiRequests[key] extends undefined ? never : key;
}[keyof ApiRequests];

export type EndpointsWithoutData = {
  [key in keyof ApiRequests]: ApiRequests[key] extends undefined ? key : never;
}[keyof ApiRequests];

export default function api<T extends EndpointsWithRequiredData>(
  endpoint: T,
  data: ApiRequests[T],
): Promise<ApiResponses[T]>;
export default function api<T extends EndpointsWithoutData>(endpoint: T): Promise<ApiResponses[T]>;
export default async function api<T extends keyof typeof apiEndpoints & keyof ApiRequests>(
  endpoint: T,
  data?: ApiRequests[T],
): Promise<ApiResponses[T]> {
  if (endpoint in apiEndpoints) {
    // Legacy endpoints
    return railsRequest(endpoint, data);
  }

  return apiRequest(endpoint as any, data as any) as any;
}

function railsRequest<T extends keyof typeof apiEndpoints>(
  endpoint: T,
  data?: ApiRequests[T],
): Promise<ApiResponses[T]> {
  data ??= {} as ApiRequests[T];
  const func = apiEndpoints[endpoint];
  const [domain, method, url] = func(data as any);
  if (domain !== "*" && getRouteOrigin(`${domain}.root`) !== location.origin)
    throw new Error(`Endpoint ${endpoint} is not allowed to be called from ${location.origin}`);

  const headers: RequestHeaders = {
    "X-Mfa-Code": mfaCode,
  };

  if (containsFile(data)) headers["Content-Type"] = "multipart/form-data";

  return railsAxiosClient
    .request<ApiResponses[T]>({
      method,
      url,
      [method === "get" ? "params" : "data"]: data,
      headers,
    })
    .then((response) => [response.headers["x-app-version"], response.status, response.data] as const)
    .then(([version, status, responseData]) => {
      if (status === 401 && standardRailsUnauthorizedResponses.includes((responseData as any).error))
        return handleRailsUnauthorizedResponse<T>(endpoint, data, (responseData as any).error);

      if ([429, 502, 503].includes(status)) return handleRateLimitResponse<T>(endpoint, data);
      if (status === 500) return handleServerError<T>(endpoint, data);

      failingSince = undefined;

      const validation = apiResponses[endpoint].safeParse(responseData);
      if (!validation.success) {
        if (process.env.NODE_ENV === "production" && process.env.CURRENT_APP_VERSION !== version) {
          location.reload();
        } else {
          const error = new Error(
            `API response for ${endpoint} is not valid. Response: ${JSON.stringify(trimStrings(responseData))}`,
          );
          if (process.env.NODE_ENV === "development") alert("Validation warning!");

          console.error(error);
          console.log(validation.error.issues);
          Sentry.captureException(error);
        }
      }

      return ("data" in validation ? validation.data : responseData) as ApiResponses[T];
    });
}

function apiRequest<T extends Extract<Exclude<keyof ApiRequests, keyof typeof apiEndpoints>, string>>(
  endpoint: T,
  data?: ApiRequests[T],
): Promise<ApiResponses[T]> {
  const parts = endpoint.split(".") as [keyof ServerPrefix, ...string[], VerbKeywords[keyof VerbKeywords]];
  const prefix = parts[0];
  const verb = parts[parts.length - 1] as VerbKeywords[keyof VerbKeywords];

  data ??= {} as any;
  const method = Object.entries(verbKeywords).find(([_, keywords]) => (keywords as any).includes(verb))?.[0]!;

  if (serverPrefixes[prefix] !== "*" && getRouteOrigin(`${serverPrefixes[prefix]}.root`) !== location.origin)
    throw new Error(`Endpoint ${endpoint} is not allowed to be called from ${location.origin}`);

  const headers: RequestHeaders = {
    "X-Mfa-Code": mfaCode,
  };

  let encodedData = serializeDates(data);

  if (containsFile(data)) {
    headers["Content-Type"] = "multipart/form-data";
    encodedData = jsonEncodeFormValues(encodedData);
  }

  return axiosClient
    .request<ApiResponses[T]>({
      method,
      url: `/api/call/${endpoint}`,
      [method === "get" ? "params" : "data"]: encodedData,
      headers,
    })
    .then((response) => [response.headers["x-app-version"], response.status, response.data] as const)
    .then(([version, status, responseData]) => {
      if (status === 403) {
        handleUnauthorizedResponse((responseData as any).requiredRole);
        return waitForever();
      }
      if (status === 402) {
        handlePaymentRequiredResponse((responseData as any).subscriptionIssues);
        return waitForever();
      }

      if ([429, 502, 503].includes(status))
        return handleRateLimitResponse<T>(endpoint, data as ApiRequests[T], responseData);

      if (status === 500) return handleServerError<T>(endpoint, data as ApiRequests[T]);

      if (status === 413) {
        Sentry.captureException(new Error(`Size too large for request to ${endpoint}.`));
        document.dispatchEvent(makeApiErrorEvent("request_too_large"));
        return waitForever();
      }
      failingSince = undefined;

      if (
        process.env.NODE_ENV === "production" &&
        process.env.CURRENT_APP_VERSION !== version &&
        process.env.CURRENT_APP_VERSION?.localeCompare(version, undefined, { numeric: true, sensitivity: "base" }) ===
          -1
      )
        document.dispatchEvent(makeNewVersionAvailableEvent());

      if (status === 400 && (responseData as any).status === "unexpected_error") {
        Sentry.captureException(new Error(`Unexpected response to ${endpoint}: ${(responseData as any).error}`));
        document.dispatchEvent(makeApiErrorEvent("unexpected"));
        return waitForever();
      }
      return deserializeDates(responseData) as ApiResponses[T];
    });
}

function failingForTooLong(): boolean {
  return failingSince !== undefined && Date.now() - failingSince.getTime() > 60_000;
}

function waitForever(): Promise<never> {
  return new Promise(() => {});
}

function handleRailsUnauthorizedResponse<T extends keyof ApiResponses>(
  endpoint: T,
  data: ApiRequests[T],
  reason: (typeof standardRailsUnauthorizedResponses)[number],
): Promise<ApiResponses[T] | never> {
  if (reason === "admin:mfa_required") {
    mfaCodePromise ??= new Promise((resolve) => {
      while (!mfaCode) mfaCode = presence(prompt("Enter MFA code"));

      resolve();
      mfaCodePromise = undefined;
    });

    return mfaCodePromise.then(() => api(endpoint as any, data as any)) as Promise<ApiResponses[T]>;
  }

  document.dispatchEvent(makeRailsErrorEvent(reason));
  return waitForever();
}

function handleUnauthorizedResponse(requiredRole: EndpointAuth) {
  document.dispatchEvent(makeApiErrorEvent({ auth: requiredRole }));
}

function handlePaymentRequiredResponse(subscriptionIssues: SubscriptionIssues) {
  document.dispatchEvent(makeApiErrorEvent({ subscriptionIssues }));
}

function handleRateLimitResponse<T extends keyof ApiResponses>(
  endpoint: T,
  data: ApiRequests[T],
  response?: unknown,
): Promise<ApiResponses[T]> {
  let retryIn = 5_000;
  failingSince ??= new Date();
  if (typeof response === "object" && response !== null && "resetsInMs" in response)
    retryIn = response.resetsInMs as number;

  console.log(`Hit rate limit, retrying in ${retryIn.toLocaleString()}ms`);
  return sleep(retryIn).then(() => api(endpoint as any, data as any)) as Promise<ApiResponses[T]>;
}

function handleServerError<T extends keyof ApiResponses>(endpoint: T, data: ApiRequests[T]): Promise<ApiResponses[T]> {
  const retryIn = 5_000;
  failingSince ??= new Date();
  if (Date.now() - failingSince.getTime() > 30_000) {
    Sentry.captureException(new Error(`Unexpected server 500 at ${endpoint}.`));
    document.dispatchEvent(makeApiErrorEvent("unexpected"));
    return waitForever();
  }
  console.log(`Got server error, retrying in ${retryIn.toLocaleString()}ms`);
  return sleep(retryIn).then(() => api(endpoint as any, data as any)) as Promise<ApiResponses[T]>;
}

export function jsonEncodeFormValues(input: any): any {
  if (typeof input === "string") {
    return JSON.stringify(input);
  } else if (Array.isArray(input)) {
    return input.map((element) => jsonEncodeFormValues(element));
  } else if (typeof input === "object" && input !== null) {
    if (!isPlainObject(input)) return input;

    const transformedObj: { [key: string]: any } = {};
    for (const key in input)
      if (Object.prototype.hasOwnProperty.call(input, key)) transformedObj[key] = jsonEncodeFormValues(input[key]);

    return transformedObj;
  } else if (input === null || typeof input === "number" || typeof input === "boolean") {
    return JSON.stringify(input);
  }
}

export function serializeDates(object?: undefined): undefined;
export function serializeDates(object?: null): null;
export function serializeDates(object?: Record<string, any>): Record<string, any>;
export function serializeDates(object?: Record<string, any> | null): Record<string, any> | null | undefined {
  if (!isPlainObject(object)) return object;

  const objectCopy = { ...object };
  for (const key in objectCopy) {
    if (objectCopy[key] instanceof Date) {
      objectCopy[key] = { __date__: objectCopy[key].toISOString() };
    } else if (objectCopy[key] instanceof Day) {
      objectCopy[key] = { __day__: objectCopy[key].toString() };
    } else if (Array.isArray(objectCopy[key])) {
      objectCopy[key] = objectCopy[key].map((item: any) => {
        if (typeof item === "object") return serializeDates(item);

        return item;
      });
    } else if (typeof objectCopy[key] === "object" && objectCopy[key] !== null) {
      objectCopy[key] = serializeDates(objectCopy[key]);
    }
  }
  return objectCopy;
}

export function deserializeDates(object?: undefined): undefined;
export function deserializeDates(object?: null): null;
export function deserializeDates(object?: Record<string, any>): Record<string, any>;
export function deserializeDates(object?: Record<string, any> | null): Record<string, any> | null | undefined {
  if (!isPlainObject(object)) return object;

  const objectCopy = { ...object };
  for (const key in objectCopy) {
    if (Array.isArray(objectCopy[key])) {
      objectCopy[key] = objectCopy[key].map((item: any) => {
        if (typeof item === "object") return deserializeDates(item);

        return item;
      });
    } else if (typeof objectCopy[key] === "object" && objectCopy[key] !== null) {
      if (objectCopy[key].__day__ !== undefined) objectCopy[key] = new Day(objectCopy[key].__day__);
      else if (objectCopy[key].__date__ !== undefined) objectCopy[key] = new Date(objectCopy[key].__date__);
      else objectCopy[key] = deserializeDates(objectCopy[key]);
    }
  }
  return objectCopy;
}

function timezone(): string | null {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch {
    return null;
  }
}
