import { z } from "zod";

import { Team } from "./Team";
import { UserTeamManagementData } from "./User";

export const TeamMembership = z.object({
  id: z.custom<`tm_${string}`>(),
  isOwner: z.boolean(),
  inGracePeriod: z.boolean(),
  createdAt: z.coerce.date(),
  isOverLimit: z.boolean(),
  license: z.boolean(),
  role: z.enum(["owner", "administrator", "user"]),
  permissions: z.object({
    canUse: z.boolean(),
    canManageContent: z.boolean(),
    canViewReporting: z.boolean(),
    canManageUsers: z.boolean(),
    canManageBilling: z.boolean(),
    canAccessSettings: z.boolean(),
    canPurchase: z.boolean(),
  }),
  team: Team.pick({ id: true, domain: true, uniqueIdentifier: true, type: true }),
  type: z.literal("team_membership"),
});

export const Invite = z.object({
  email: z.string().email(),
  emailBounced: z.boolean(),
  expired: z.boolean(),
  id: z.string(),
  permissions: z.object({
    canUse: z.boolean(),
    canManageContent: z.boolean(),
    canViewReporting: z.boolean(),
    canManageUsers: z.boolean(),
    canManageBilling: z.boolean(),
    canAccessSettings: z.boolean(),
  }),
  sentAt: z.coerce.date(),
  type: z.literal("invite"),
});

export type TeamMembership = z.infer<typeof TeamMembership>;

export const TeamMembershipTeamManagementData = TeamMembership.pick({
  id: true,
  createdAt: true,
  inGracePeriod: true,
  isOverLimit: true,
  isOwner: true,
  license: true,
  permissions: true,
  team: true,
  role: true,
  type: true,
}).merge(
  z.object({
    user: UserTeamManagementData,
  }),
);

export type TeamMembershipTeamManagementData = z.infer<typeof TeamMembershipTeamManagementData>;
export type Invite = z.infer<typeof Invite>;
