import { useMemo, useRef } from 'react';
import { useUnmountEffect } from '../useUnmountEffect';
/**
 * Makes passed function debounced, otherwise acts like `useCallback`.
 *
 * @param callback Function that will be debounced.
 * @param deps Dependencies list when to update callback.
 * @param delay Debounce delay.
 * @param maxWait The maximum time `callback` is allowed to be delayed before
 * it's invoked. 0 means no max wait.
 */
export function useDebouncedCallback(callback, deps, delay, maxWait = 0) {
    const timeout = useRef();
    const waitTimeout = useRef();
    const lastCall = useRef();
    const clear = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
            timeout.current = undefined;
        }
        if (waitTimeout.current) {
            clearTimeout(waitTimeout.current);
            waitTimeout.current = undefined;
        }
    };
    // Cancel scheduled execution on unmount
    useUnmountEffect(clear);
    return useMemo(() => {
        const execute = () => {
            // Barely possible to test this line
            /* istanbul ignore next */
            if (!lastCall.current)
                return;
            const context = lastCall.current;
            lastCall.current = undefined;
            callback.apply(context.this, context.args);
            clear();
        };
        const wrapped = function (...args) {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
            lastCall.current = { args, this: this };
            // Plan regular execution
            timeout.current = setTimeout(execute, delay);
            // Plan maxWait execution if required
            if (maxWait > 0 && !waitTimeout.current) {
                waitTimeout.current = setTimeout(execute, maxWait);
            }
        };
        Object.defineProperties(wrapped, {
            length: { value: callback.length },
            name: { value: `${callback.name || 'anonymous'}__debounced__${delay}` },
        });
        return wrapped;
    }, [delay, maxWait, ...deps]);
}
