import { z } from "zod";
import { UserBaseData } from "./User";

export const AdminNoteVersion = z.object({
  id: z.string(),
  note: z.string(),
  editedBy: UserBaseData.nullable(),
  editedAt: z.coerce.date(),
  type: z.literal("company_admin_note_version"),
});

export type AdminNoteVersion = z.infer<typeof AdminNoteVersion>;
