import humps from "humps";

import DataTypes from "~data";
import { Product } from "~data/Product";

import { DirectLink } from "./components/ui/Link";
import { CustomerSuccessStage } from "./data/models/partials/CustomerSuccessStage";
import { OauthProvider } from "./data/models/partials/OauthProvider";
import { UtmParams } from "./data/models/partials/UtmParams";
import Day from "./utils/Day";

const { decamelize } = humps;

export type LinkShowSection = "viewers" | "edit" | "stats";

export const paths = {
  "up.auth.signUp": "/auth/sign_up",
  "up.auth.login": (p: { returnHere?: boolean; redirectUrl?: string }) =>
    "/auth/login" +
    query({
      redirectUrl: p.redirectUrl ?? (p.returnHere ? location.href : null),
    }),
  "up.auth.loginRedirect": () =>
    "/api/auth/redirect" +
    query({
      redirectUrl: location.href,
    }),
  "up.auth.join": (p: { teamDomain: string; teamUniqueIdentifier: string }) =>
    `/join/${p.teamUniqueIdentifier}-${p.teamDomain}`,
  "up.oauth.signUp": (p: {
    provider: OauthProvider;
    utmParams?: UtmParams;
    ignoreTakenDomain?: boolean;
    product?: Product;
    integration?: string;
  }) =>
    `/auth/sign_up/with/${p.provider}` +
    `${query({ ...p.utmParams, ignoreTakenDomain: p.ignoreTakenDomain, product: p.product, integration: p.integration })}`,
  "up.auth.mfa.enable": "/auth/mfa/setup",
  "up.notifications.confirmation": "/email_notification",
  "up.shortCuts.purchase": "/purchase",
  "up.shortCuts.install": "/install",
  "up.onboarding": (p: { teamDomain: string }) => `/welcome/${p.teamDomain}`,
  "up.onboarding.questions": (p: { teamDomain: string }) => `/welcome/${p.teamDomain}/questions`,
  "up.onboarding.bookCall": (p: { teamDomain: string }) => `/welcome/${p.teamDomain}/book_call`,
  "up.onboarding.inviteTeam": (p: { teamDomain: string }) => `/welcome/${p.teamDomain}/invite_team`,
  "up.onboarding.purchase": (p: { teamDomain: string }) => `/welcome/${p.teamDomain}/purchase`,
  "up.onboarding.install": (p: { teamDomain: string; teamUniqueIdentifier: string }) =>
    `/welcome/${p.teamDomain}/install-${p.teamUniqueIdentifier}`,
  "up.oauth.google.connect": (p: { team: DataTypes["Teams"]["id"]; redirectUrl: string }) =>
    `/auth/with/google_oauth2` + `${query({ intent: "connect", team: p.team, redirectUrl: p.redirectUrl })}`,
  "up.oauth.salesforce.connect": (p: { team: DataTypes["Teams"]["id"]; sandbox?: boolean; redirectUrl: string }) =>
    `/auth/with/${p.sandbox ? "salesforcesandbox" : "salesforce"}` +
    `${query({ intent: "connect", team: p.team, redirectUrl: p.redirectUrl })}`,
  "hs.extension.view": (p: { sessionCode: string }) => `/extension/${p.sessionCode}`,
  "hs.extension.welcome": "/extension/welcome",
  "hs.visitorsList": (p: { searchTerm?: string; isEmbedded?: boolean }) =>
    `${p.isEmbedded ? "/embed" : ""}/${typeof p.searchTerm !== "undefined" ? `s/${p.searchTerm}` : ""}`,
  "hs.cobrowse": (p: {
    visitorId: string;
    isEmbedded?: boolean;
    onPremiseTeamUniqueIdentifier?: string | null;
    source?: string;
    metadataToken?: string;
  }) => {
    if (p.onPremiseTeamUniqueIdentifier) {
      return (
        `/onprem_redirect/${p.onPremiseTeamUniqueIdentifier}` +
        query({
          metadataToken: p.metadataToken,
          source: p.source,
          id: p.visitorId,
        })
      );
    }
    return (
      `${p.isEmbedded ? "/embed" : ""}/w/${p.visitorId}` +
      query({
        metadataToken: p.metadataToken,
        source: p.source,
      })
    );
  },
  "up.settings.authTokens.new": "/auth/tokens/new",
  "up.settings.personal": "/settings",
  "up.settings.manageTeams": "/manage_teams",
  "up.settings.general": (p: { teamDomain: string; section?: string }) =>
    `/settings/teams/${p.teamDomain}${p.section ? `/${p.section}` : ""}`,
  "co.root": "/",
  "hs.root": "/",
  "up.root": "/",
  "ld.root": "/",
  "up.reporting.usage": (p: { teamDomain: string; from?: Day; to?: Day }) =>
    `/usage/${p.teamDomain}${query({
      from: p.from?.toString(),
      to: p.to?.toString(),
    })}`,
  "up.visitorData": (p: { teamDomain: string }) => `/settings/teams/${p.teamDomain}/visitor-data`,
  "up.members": (p: { teamDomain: string }) => `/settings/teams/${p.teamDomain}/members`,
  "up.members.invites": (p: { teamDomain: string }) => `/settings/teams/${p.teamDomain}/members/invites`,
  "co.dashboard": (p: { teamDomain: string }) => `/dashboard/${p.teamDomain}`,
  "co.dashboard.restKeys": (p: { teamDomain: string }) => `/dashboard/${p.teamDomain}/rest_keys`,
  "co.dashboard.requestHistory": (p: { teamDomain: string }) => `/dashboard/${p.teamDomain}/history`,
  "co.dashboard.webhookHistory": (p: { teamDomain: string }) => `/dashboard/${p.teamDomain}/webhooks`,
  "hq.root": "/",
  "hq.accounts.index": (p: { tag?: DataTypes["HqTags"]["id"] }) => `/accounts${query({ tags: p.tag })}`,
  "hq.accounts.show": (p: { companyId: string }) => `/accounts/${p.companyId}`,
  "hq.accounts.show.team": (p: { companyId: string; teamId: string }) => `/accounts/${p.companyId}/teams/${p.teamId}`,
  "hq.accounts.show.emails": (p: { companyId: string }) => `/accounts/${p.companyId}/emails`,
  "hq.accounts.show.contacts": (p: { companyId: string }) => `/accounts/${p.companyId}/contacts`,
  "hq.accounts.show.companyDocuments": (p: { companyId: string }) => `/accounts/${p.companyId}/company_documents`,
  "hq.accounts.show.members": (p: { companyId: string; teamId: string }) =>
    `/accounts/${p.companyId}/teams/${p.teamId}/members`,
  "hq.accounts.show.usage": (p: { companyId: string; teamId: string }) =>
    `/accounts/${p.companyId}/teams/${p.teamId}/usage`,
  "hq.accounts.show.activityLog": (p: { companyId: string }) => `/accounts/${p.companyId}/activity_log`,
  "hq.accounts.show.charges": (p: { companyId: string; teamId: string }) =>
    `/accounts/${p.companyId}/charges/${p.teamId}`,
  "hq.customerSuccess.index": "/cs",
  "hq.customerSuccess.list": (
    p: {
      stage: CustomerSuccessStage | ":stage";
    }, // :stage for route generation
  ) => `/cs/${p.stage}`,
  "hq.globalActivityLog": (p: { userId?: DataTypes["Users"]["id"] }) => `/activity_log${query({ userId: p.userId })}`,
  "hq.sales.index": "/sales",
  "hq.sales.tasks": "/sales/tasks",
  "hq.sales.leads": "/sales/leads",
  "hq.sales.activities": "/sales/activities",
  "hq.sales.opportunities.index": "/sales/opportunities",
  "hq.sales.opportunities.show": (p: { opportunityId: string }) => `/sales/opportunities/${p.opportunityId}`,
  "hq.users.show": (p: { userId: string }) => `/users/${p.userId}`,
  "hq.quotes.transfer.new": (p: { quotePublicKey: string }) => `/quotes/${p.quotePublicKey}/transfer`,
  "hq.quotes.show": (p: { quotePublicKey: string }) => `/quotes/${p.quotePublicKey}`,
  "hq.quotes.index": "/quotes",
  "hq.debug.show": (p: { visitorId: string }) => `/debug/${p.visitorId}`,
  "ld.templates.index": (p: { teamDomain: string }) => `/templates/${p.teamDomain}`,
  "ld.templates.show": (p: { teamDomain: string; templateId: string }) => `/templates/${p.teamDomain}/${p.templateId}`,
  "ld.templates.create": (p: { teamDomain: string }) => `/templates/${p.teamDomain}/new`,
  "ld.links.list": (p: { teamDomain: string }) => `/links/${p.teamDomain}`,
  "ld.links.create": (p: { teamDomain: string }) => `/links/${p.teamDomain}/new`,
  "ld.links.show": (
    p:
      | {
          linkSlug: string;
          pageNumber: number;
          section?: "preview";
          documentId: string;
        }
      | {
          linkSlug: string;
          section?: LinkShowSection;
          documentId?: undefined;
          pageNumber?: undefined;
        },
  ) => `/${p.linkSlug}${p.section ? `/${p.section}` : ""}${query({ document: p.documentId, page: p.pageNumber })}`,
  "up.settings.team": (p: { teamDomain: string }) => `/settings/teams/${p.teamDomain}/members`,
  "up.settings.billing": (p: { teamDomain: string }) => `/settings/teams/${p.teamDomain}/billing`,
  "up.settings.billing.sessionCredit": (p: { teamDomain: string }) =>
    `/settings/teams/${p.teamDomain}/billing/session-credit`,
  "up.settings.billing.subaccounts": (p: { teamDomain: string }) =>
    `/settings/teams/${p.teamDomain}/billing/subaccounts`,
  "up.settings.billing.history": (p: { teamDomain: string }) => `/settings/teams/${p.teamDomain}/billing/history`,
  "up.activity.activityLog": (p: { teamDomain: string; userId?: DataTypes["Users"]["id"] }) =>
    `/activity_log/${p.teamDomain}${query({ userId: p.userId })}`,
  "up.history": (p: { teamDomain: string; sessionId?: string }) =>
    `/usage/${p.teamDomain}/sessions${p.sessionId ? `/${p.sessionId}` : ""}`,
  "up.integrations": (p: { teamDomain: string }) => `/settings/teams/${p.teamDomain}/integrations`,
  "up.integrations.googleOauth2": (p: { teamDomain: string }) =>
    `/settings/teams/${p.teamDomain}/integrations/add/google_oauth2`,
  "up.integrations.livechat": (p: { teamDomain: string }) =>
    `/settings/teams/${p.teamDomain}/integrations/add/livechat`,
  "up.integrations.twilioFlex": (p: { teamDomain: string; integrationId: string }) =>
    `/settings/teams/${p.teamDomain}/integrations/add/twilio_flex/${p.integrationId}`,
  "up.integrations.freshchat": (p: { teamDomain: string; integrationId: string }) =>
    `/settings/teams/${p.teamDomain}/integrations/add/freshchat/${p.integrationId}`,
  "up.integrations.front": (p: { teamDomain: string; integrationId?: string }) =>
    `/settings/teams/${p.teamDomain}/integrations/add/front${p.integrationId ? `/${p.integrationId}` : ""}`,
  "up.integrations.salesforce": (p: { teamDomain: string }) =>
    `/settings/teams/${p.teamDomain}/integrations/add/salesforce`,
  "up.integrations.salesforceSandbox": (p: { teamDomain: string }) =>
    `/settings/teams/${p.teamDomain}/integrations/add/salesforce?sandbox=true`,
  "up.integrations.zapier": (p: { teamDomain: string; integrationId: string }) =>
    `/settings/teams/${p.teamDomain}/integrations/add/zapier/${p.integrationId}`,
  "up.integrations.zendesk": (p: { teamDomain: string; integrationId: string }) =>
    `/settings/teams/${p.teamDomain}/integrations/add/zendesk/${p.integrationId}`,
  "up.integrations.intercom.settings": (p: { teamDomain: string; integrationId: string }) =>
    `/settings/teams/${p.teamDomain}/integrations/intercom/${p.integrationId}/settings`,
  "up.integrations.salesforce.settings": (p: { teamDomain: string; integrationId: string }) =>
    `/settings/teams/${p.teamDomain}/integrations/salesforce/${p.integrationId}/settings`,
  "up.quotes.show": (p: { quotePublicKey: string; orderForm?: boolean }) =>
    `/quote/${p.quotePublicKey}${query({ orderForm: p.orderForm })}`,
  "up.invoice.showFailed": (p: { invoiceId: string }) => `/invoice/${p.invoiceId}/failed`,
} as const;

export const legacyPaths = {
  "up.auth.saml.redirect": (p: { teamUniqueIdentifier: string; returnHere?: boolean; redirectUrl?: string }) =>
    `/auth/saml/${p.teamUniqueIdentifier}/authenticate${query({ redirectUrl: p.redirectUrl ?? (p.returnHere ? location.href : null) })}`,

  "uv.root": "/",
  "web.root": "/",
  "web.legal": "/legal",
  "web.demo": "/demo",
  "web.extension": "/extension",
  "web.security": "/security",
  "web.apiDocs": "/co-browsing-api/docs/rest-api",
  "hq.impersonation.stop": "/users/impersonation/stop",
  "up.reporting.usage.download": (p: { teamDomain: string; from: Day; to: Day }) =>
    `/usage/${p.teamDomain}/download${query({
      from: p.from.toString(),
      to: p.to.toString(),
    })}`,
} as const;

const allPaths = {
  ...paths,
  ...legacyPaths,
};

export type StaticPaths = {
  [key in keyof typeof allPaths]: (typeof allPaths)[key] extends string ? key : never;
}[keyof typeof allPaths];

export type DynamicPaths = {
  [key in keyof typeof allPaths]: (typeof allPaths)[key] extends Function ? key : never;
}[keyof typeof allPaths];

export type PathParameters =
  | {
      [key in DynamicPaths]: [key, ...Parameters<(typeof allPaths)[key]>];
    }[DynamicPaths]
  | {
      [key in StaticPaths]: [key];
    }[StaticPaths];

export function path(...pathData: PathParameters): DirectLink {
  const [pathName, ...args] = pathData;
  let path;
  path =
    typeof allPaths[pathName] === "string"
      ? allPaths[pathName as StaticPaths]
      : (allPaths[pathName as DynamicPaths] as (args: any) => string).apply(null, args as any);

  const origin = getRouteOrigin(pathName);

  if (origin !== location.origin) {
    path = `${origin}${path}`;
    if (new URL(path).pathname === "/") path += path.includes("?") ? "&authenticated=true" : "?authenticated=true";
  }

  return path as DirectLink;
}

export function getRouteOrigin(routeKey: keyof typeof allPaths): string {
  const tld = location.hostname.split(".").pop()!;
  const protocol = location.protocol;

  if (routeKey.startsWith("web.")) return `${protocol}//upscope.${tld === "io" ? "com" : tld}`;

  if (routeKey.startsWith("up.")) return `${protocol}//app.upscope.${tld === "com" ? "io" : tld}`;
  // TODO: Move app to .com

  if (routeKey.startsWith("hq.")) return `${protocol}//hq.upscope.${tld === "com" ? "io" : tld}`;
  // TODO: Move app to .com

  if (routeKey.startsWith("uv.")) return `${protocol}//userview.${tld === "io" ? "com" : tld}`;

  if (routeKey.startsWith("ld.")) return `${protocol}//livedocument.${tld === "io" ? "com" : tld}`;

  if (routeKey.startsWith("hs.")) return `${protocol}//helloscreen.${tld === "io" ? "com" : tld}`;

  if (routeKey.startsWith("co.")) return `${protocol}//cobrowsingapi.${tld === "io" ? "com" : tld}`;

  throw new Error("Unknown path prefix");
}

export function getRoot(service: "up" | "hq" | "uv" | "hs" | "co" | "ld") {
  return getRouteOrigin(`${service}.root`) + "/";
}

export function cleanRedirectPath(url: string): string {
  if (url.startsWith("/")) return getRoot("up").slice(0, -1) + url;

  if (
    url.startsWith(getRoot("up")) ||
    url.startsWith(getRoot("uv")) ||
    url.startsWith(getRoot("hs")) ||
    url.startsWith(getRoot("hq")) ||
    url.startsWith(getRoot("ld")) ||
    url.startsWith(getRoot("co"))
  )
    return url;

  return getRoot("up");
}

export function isTrustedOrigin(origin: string): boolean {
  const originRoot = origin + "/";

  return (
    originRoot === getRoot("up") ||
    originRoot === getRoot("uv") ||
    originRoot === getRoot("hs") ||
    originRoot === getRoot("hq") ||
    originRoot === getRoot("ld") ||
    originRoot === getRoot("co")
  );
}

function query(object: Record<string, any>) {
  const params = Object.entries(object).filter(([key, value]) => value !== null && value !== undefined);

  if (params.length === 0) return "";

  return "?" + params.map(([key, value]) => `${decamelize(key)}=${encodeURIComponent(value)}`).join("&");
}
