import { z } from "zod";

export const CobrowsingSessionWebhookLog = z.object({
  id: z.string(),
  webhookUrl: z.string(),
  status: z.string(),
  statusCode: z.number(),
  timedOut: z.boolean(),
  createdAt: z.coerce.date(),
  willRetryAt: z.coerce.date().nullable(),
  session: z.object({
    id: z.string(),
    type: z.literal("cobrowsing_session"),
  }),
});

export type CobrowsingSessionWebhookLog = z.infer<typeof CobrowsingSessionWebhookLog>;
