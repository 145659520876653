import z from "zod";

export const FrontIntegration = z.object({
  id: z.custom<`front_${string}`>(),
  createdAt: z.coerce.date(),
  integrationType: z.literal("front"),
  apiKey: z.string(),
  isUsed: z.boolean(),
  lastUsedAt: z.coerce.date(),
  name: z.string(),
  teamId: z.custom<`t_${string}`>(),
  type: z.literal("front_integration"),
});

export type FrontIntegration = z.infer<typeof FrontIntegration>;
