---
"pt-BR":
  "t_1_day_ago": >-
    1 dia atrás
  "t_1_hour_ago": >-
    1 hora atrás
  "t_1_minute_ago": >-
    1 minuto atrás
  "t_billing": >-
    Cobrança
  "t_cannot_be_set_when_there_is_an_existing_subscription": >-
    cannot be set when there is an existing subscription
  "t_days_days_ago": >-
    %{days} dias atrás
  "t_history": >-
    Histórico
  "t_hours_hours_ago": >-
    %{hours} horas atrás
  "t_install": >-
    Instalar
  "t_integrations": >-
    Integrações
  "t_join_teamdomain_on_upscope": >-
    Junte-se à %{teamDomain} no Upscope
  "t_just_now": >-
    agora mesmo
  "t_minutes_minutes_ago": >-
    %{minutes} minutos atrás
  "t_please_enter_a_valid_email_address": >-
    Por favor, insira um e-mail válido
  "t_settings": >-
    Configurações
  "t_something_went_wrong_please_try_again_later": >-
    Algo deu errado. Por favor tente novamente mais tarde.
  "t_team": >-
    Time
  "t_usage": >-
    Uso
