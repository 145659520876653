import { z } from "zod";

import { Url } from "./partials/Url";

export const Charge = z.object({
  id: z.string(),
  show: z.boolean(),
  succeeded: z.boolean(),
  failed: z.boolean(),
  date: z.coerce.date(),
  amountFormatted: z.string(),
  // TODO: Should disputed be nullable?
  disputed: z.boolean().nullable(),
  pending: z.boolean(),
  refunded: z.boolean(),
  amountRefunded: z.number(),
  invoiceUrl: Url.nullable(),
  type: z.literal("charge"),
  amountRefundable: z.number(),
  currency: z.enum(["usd", "gbp", "eur"]),
});

export type Charge = z.infer<typeof Charge>;

export function chargeStatusLabel(charge: Omit<Charge, "amountRefundable">) {
  if (charge.succeeded) return "Succeeded";

  if (charge.failed) return "Failed";

  if (charge.disputed) return "Disputed";

  if (charge.refunded) return "Refunded";

  if (charge.pending) return "Pending";

  return "Unknown";
}
