import { Product } from "../data/models/partials/Product";
import type { Plan } from "../data/models/Plan";

export function productName(identifier: Product): string {
  switch (identifier) {
    case "helloscreen":
      return "HelloScreen";
    case "userview":
      return "UserView";
    case "livedocument":
      return "LiveDocument";
    case "cobrowsingapi":
      return "Co-Browsing API";
  }
}

export function licenseType(type: Plan["licenseType"]): string {
  switch (type) {
    case "concurrent":
      return "Concurrent";
    case "agent":
      return "Named agent";
    case "seat":
      return "Seat";
  }
}

export function billingFrequency(period: Plan["period"]): string {
  switch (period) {
    case "year":
      return "Yearly";
    case "month":
      return "Monthly";
    case "quarter":
      return "Quarterly";
    case "six_month":
      return "Every six months";
    case "two_month":
      return "Every two months";
  }
}
